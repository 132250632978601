<template>
    <div class="flex flex-col border rounded-lg bg-gray-50  py-4"
         :class="{
                'w-full': isSetup,
                'lg:w-[400px] px-4': !isSetup
            }"
    >
        <p class="text-3xl font-bold text-black mb-5">
            Business Pro
            <br>
            <AppBadge class="mr-1 bg-primary text-white">
                Outbound Calls
            </AppBadge>
            <AppBadge>
                Workflows
            </AppBadge>
        </p>

        <table class="text-left align-middle">
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                    Everything in Base &amp; Business
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                    For medium to high call volume
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                    For 1-5 business locations
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                                        <span>
                                           Max. 15 Workflows
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            Make Outbound Calls
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            Run Custom GPT Prompts
                                        </span>
                </td>
            </tr>

            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            <b>800 Minutes Included</b>, additional minutes 16 cents per minute.
                                        </span>
                </td>
            </tr>

            <tr class="align-middle">
                <td class="pb-2">
                    <MdiIcon :icon="mdiCloseCircleOutline" class="size-5 text-red-500"/>
                </td>
                <td class="pb-2">
                    Limited to 25 outbound calls/day
                </td>
            </tr>
        </table>

        <hr class="mt-auto mb-3">
        <p
            class="text-2xl"
        >
            {{ products[2].formatted_price }}/mo
        </p>
        <p class="text-sm text-gray-500">
            + 16 cents per minute (800 mins included)
        </p>

        <div class="py-4 lg:py-8 flex items-center justify-center gap-x-6">
            <PrimaryButton
                v-if="!isSetup"
                @click="() => router.visit(route('register.with-price', {price: products[2].value}), { method: 'get' })"
                class="scale-125 lg:scale-150 origin-center">
                Start Your Free Trial
            </PrimaryButton>
            <PrimaryButton
                v-if="isSetup"
                @click="emit('submit', products[2].value)"
                :disabled="loading"
                :class="{
                    'opacity-25': loading
                }"
                class="lg:scale-125"
            >
                <slot>
                    Start Free Trial
                </slot>
            </PrimaryButton>
        </div>
        <p class="text-xs lg:-mt-3" v-if="!isSetup">
            Start your free, no-obligation 7 day trial today.
            <br>
            <b>No credit card required</b>
        </p>
    </div>

</template>
<script setup lang="ts">
import logoBlack from "../../../../images/logo-black.svg";
import {router, usePage} from "@inertiajs/vue3";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {CheckCircleIcon} from "@heroicons/vue/20/solid/index";
import AppBadge from "@/Components/AppBadge.vue";
import {mdiCloseCircleOutline} from "@mdi/js";
import MdiIcon from "@/Components/MdiIcon.vue";
//import {route} from "ziggy-js";
const products = usePage().props.products


defineProps(['isSetup', 'loading'])

const emit = defineEmits(['submit'])
</script>
